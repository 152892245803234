import request from '../utils/request';

const fetchData = query => {
    return request({
        url: '/table.json',
        method: 'get',
        params: query
    });
};

const authLogin = query => {
    return request({
        url: '/auth/login',
        method: 'post',
        data: query
    });
};

const productList = query => {
    return request({
        url: '/product/list',
        method: 'get',
        params: query
    });
};

const catList = query => {
    return request({
        url: '/cat/list',
        method: 'get',
        params: query
    });
};
const productDel = query => {
    return request({
        url: 'product/del',
        method: 'post',
        data: query
    });
};

const productUpdate = query => {
    return request({
        url: "product/update",
        method: "post",
        data: query
    })
}

const productUpdateProductImg = query => {
    return request({
        url: "product/updateProductImg",
        method: "post",
        data: query
    })
}

const imageUpload_img = query => {
    return request({
        url: "image/upload_img",
        method: "post",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: query
    })
}

const productExport = query => {
    return request({
        url: "/product/export",
        method: "post",
        data: query
    })
}

const productImport = query => {
    return request({
        url: "/product/import",
        method: "post",
        headers: {
            "Content-Type": "multipart/form-data"
        },
        data: query
    })
}

const catAdd = query => {
    return request({
        url: "/cat/add",
        method: "post",
        data: query
    })
}
const catDel = query => {
    return request({
        url: "/cat/del",
        method: "post",
        data: query
    })
}
//分类更新
const catUpdate = query => {
    return request({
        url: "/cat/update",
        method: "post",
        data: query
    })
}
//角色编辑
const rbacRoleEdit = query => {
    return request({
        url: "/rbac/roleEdit",
        method: "post",
        data: query
    })
}
//权限菜单
const rbacMenu = query => {
    return request({
        url: "/rbac/menu",
        method: "post",
        data: query
    })
}

//获取角色
const rbacRole = query => {
    return request({
        url: "/rbac/role",
        method: "get",
        params: query
    })
}
const rbacRoleAdd = query=>{
    return request({
        url:"/rbac/roleAdd",
        method:"post",
        data:query
    })
}
const rbacRoleDelete = query=>{
    return request({
        url:"/rbac/roleDelete",
        method:"post",
        data:query
    })
}
const rbacAuthorize = query=>{
    return request({
        url:"/rbac/authorize",
        method:"post",
        data:query
    })
}
const rbacAuthorizeEdit = query=>{
    return request({
        url:"/rbac/authorizeEdit",
        method:"post",
        data:query
    })
}
// const rbacAuthorizeEdit = query=>{
//     return request({
//         url:"/rbac/authorizeEdit",
//         method:"post",
//         data:query
//     })
// }
const manageList = query=>{
    return request({
        url:"/manage/list",
        method:"post",
        data:query
    })

}

const manageUpdate = query=>{
    return request({
        url:"/manage/update",
        method:"post",
        data:query
    })

}

const manageAdd = query=>{
    return request({
        url:"/manage/add",
        method:"post",
        data:query
    })

}

const manageDel = query => {
    return request({
        url: "/manage/del",
        method: "post",
        data: query
    })
}

const rbacAuthRoleEdit = query=>{
    return request({
        url:"/rbac/authRoleEdit",
        method:"post",
        data:query
    })
}

const dashboard = query=>{
    return request({
        url:"/dashboard",
        method:"post",
        data:query
    })
}
//日志的接口
const rbacLog = query=>{
    return request({
        url:"/rbac/log",
        method:"post",
        data:query
    })
}
// export default {
//     fetchData,
//     authLogin
// }
export default {
    fetchData,
    authLogin,
    productList,
    catList,
    catAdd,
    productDel,
    productUpdate,
    productUpdateProductImg,
    imageUpload_img,
    productExport,
    productImport,
    catDel,
    catUpdate,
    rbacRoleEdit,
    rbacMenu,
    rbacRole,
    rbacRoleAdd,
    rbacRoleDelete,
    rbacAuthorize,
    rbacAuthorizeEdit,
    manageList,
    manageUpdate,
    manageAdd,
    manageDel,
    rbacAuthRoleEdit,
    dashboard,
    rbacLog

    
}
