import axios from 'axios';
import { ElMessage } from 'element-plus'
const service = axios.create({
    //https://yiwutiancheng.cn/
    //http://192.168.31.100:91
    baseURL: process.env.NODE_ENV === 'development' ? "http://192.168.31.100:91" : "",
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
    timeout: 30000
});

service.interceptors.request.use(
    config => {
        let token = window.localStorage.getItem("token")
        // config.headers['Authorization'] = token
        config.headers['token'] = token || ''
        return config;
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

service.interceptors.response.use(
    response => {
        if (response.data.status === 200) {
            return response.data || response;
        }
        else if (response.data.code === 401) {
            ElMessage({
                type: "error",
                message: '登录过期,请重新登录'

            })


        }

        else if (response.data.code === 200) {
            return response.data || response;


        }
        else if (response.data.code !== 200||response.data.status!== 200) {
            ElMessage({
                type: "error",
                message: response.data.msg

            })
            return response.data;

        }
        else {
            return Promise.reject();
        }
    },
    error => {
        console.log(error);
        return Promise.reject();
    }
);

export default service;
